// @ts-nocheck
import { Key, ReactElement, useEffect, useState } from "react"

import CssBaseline from "@mui/material/CssBaseline"
import theme from "material-ui/theme"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "authConfig"
import { mainRoutes, adminRoutes, superAdminRoutes } from "routes"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import logger from "loglevel"
import ServiceBell from "@servicebell/widget"
import TagManager from "react-gtm-module"
import { LicenseInfo } from '@mui/x-license-pro'
import "assets/styles/index.scss"
import { Grid } from "@mui/material";
import LoadingModal from "./components/loader-modal";
import { useQuery } from "@tanstack/react-query"
import ProfileService from "./services/profile";
import MDBox from "./material-ui/components/MDBox";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./pages/error";
import { userHasHailTrace } from "./helpers/profileHelper";
import { useLocation } from "react-router-dom"
import SelectTemplateModal from "components/select-template-modal"
import Sidebar from "newStandard/src/components/Layout/Sidebar"
import { useGlobal } from "context/global-context"
import { sidebarFullWidth } from "newStandard/src/components/Layout/Sidebar/StyledSidebar"
import LogRocket from 'logrocket';
import Footer from "newStandard/src/components/Layout/Footer"
import ShopifyCallback from "pages/shopify-callback"
import SubscriptionCallback from "pages/subscriptions/subscription-callback"
import SubscriptionRedirect from "pages/subscriptions/subscription-redirect"
import FailedPaymentModal from "newStandard/src/components/FailedPaymentModal"

const tagManagerArgs = {
    gtmId: "GTM-NCGJ8S6",
}

export default function App() {
    const navigate = useNavigate()
    const { isSidebarOpen } = useGlobal()
    const { getProfile } = ProfileService()
    const deeplinkPathAndQueryKey = 'deeplink:pathAndQuery';
    const location = useLocation();

    const [routes, setRoutes] = useState([])


    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
    LicenseInfo.setLicenseKey('2c67f93452527b8d44fea7bce8c731bdTz05ODQwMixFPTE3NTgzMDA5NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: getProfile,
        refetchOnWindowFocus: false
    })

    if (ENVIRONMENT === "prd") {
        TagManager.initialize(tagManagerArgs)
        ServiceBell.init("111af27bf53346589f93b2d580564a1a")
        LogRocket.init(`lettrlabs/lettrlabs-${ENVIRONMENT}`);
    }
    switch (ENVIRONMENT) {
        case "prd":
            logger.setLevel("error")
            break
        default:
            logger.setLevel("debug")
            break
    }

    const getRoutes = (routes: any[]): any =>
        routes.map(
            (route: {
                collapse: any
                route: string
                component: ReactElement
                key: Key
            }) => {
                if (route.collapse) {
                    return getRoutes(route.collapse)
                }

                if (route.route) {
                    return <Route path={route.route} element={route.component} key={route.key} />
                }

                return null
            }
        )

    const authRequest = {
        ...loginRequest,
    }

    useEffect(() => {
        let routesToSet = [...mainRoutes]
        if (getProfileQuery.data) {
            let profile = getProfileQuery.data

            if(profile) {
                LogRocket.identify(profile.id, {
                    name: `${profile.firstName} ${profile.lastName}`,
                    email: profile.email,
                    company: profile.companyName,
                    industry: profile.industry,
                    subIndustry: profile.subIndustry,
                    subscription: profile.customerSubscription?.subscription?.name,
                    subscriptionVersion: profile.customerSubscription?.version,
                    subscriptionBalance: profile.customerSubscription?.balance,
                    subscriptionStatus: profile.customerSubscription?.customerSubscriptionStatus,
                    autoBill: profile.autobill ? 'Yes':'No',
                    bonusBalance: profile.balance
                });
            }

            let isHailTraceVisible = userHasHailTrace(profile)
            const isAdmin = profile.loggedInUser.isAdmin

            if (isHailTraceVisible || isAdmin) {
                for (let route of routes) {
                    if ((isHailTraceVisible && route.key === "storm-automation")) {
                        route.hide = false
                    }
                }
            }

            const deeplinkPathAndQuery = localStorage.getItem(deeplinkPathAndQueryKey);
            if (profile.id === 0 || !profile.loggedInUser.firstName || !profile.loggedInUser.lastName || !profile.loggedInUser.industry) {
                //If we have a new profile, we want to retain the url to forward to after the profile is filled out
                const pathAndQuery = location.pathname + location.search;
                logger.info("Setting deeplink path and query", pathAndQuery);
                localStorage.setItem(deeplinkPathAndQueryKey, pathAndQuery);
                navigate("settings/profile");
            }
            //If we previously had a new profile, we want to navigate to the saved path
            else if (deeplinkPathAndQuery) {
                logger.info("Navigating to deeplink", deeplinkPathAndQuery);
                localStorage.removeItem(deeplinkPathAndQueryKey);
                navigate(deeplinkPathAndQuery);
            }
            else {
                if (ENVIRONMENT === "prd") {
                    var serviceBellUser = {
                        email: profile.email, // REQUIRED: Required to create or associate a contact with the visitor.
                        displayName:
                            profile.firstName +
                            " " +
                            profile.lastName, // Changes the name of the visitor in the dashboard
                        customerStatus: profile.profileStatus,
                    };
                    logger.info("sending identification to servicebell", serviceBellUser);
                    ServiceBell.identify(profile.email, serviceBellUser);
                }

                if (isAdmin) {
                    routesToSet.push({ type: "divider" })
                    routesToSet.push(...adminRoutes)
                }

                if (
                    profile.loggedInUser.email.toLowerCase() === "fabio@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "brandon@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "kornel@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "jordan@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "heather@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "arik@lettrlabs.com" ||
                    profile.loggedInUser.email.toLowerCase() === "dan@lettrlabs.com"
                ) {
                    routesToSet.push({ type: "divider" })
                    const saRoutes = { ...superAdminRoutes };
                    if (profile.email.toLowerCase() === 'brandon@lettrlabs.com' && Array.isArray(saRoutes.collapse)) {
                        const routeIndex = saRoutes.collapse.findIndex((el) => el.route === '/superadmin/discount-approval');
                        if (routeIndex >= 0) saRoutes.collapse[routeIndex].hide = false;
                    }
                    routesToSet.push(saRoutes);
                }
            }
        }

        setRoutes(routesToSet)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getProfileQuery.data])

    const mainBoxStyle = () => ({
        overflowX: "hidden",
        paddingLeft: isSidebarOpen ? `${sidebarFullWidth + 16}px` : "80px",
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    })

    if (location.pathname.includes("oauth/callback")) return null
    return (
        <>
            <CssBaseline />

            <MDBox sx={{ backgroundColor: theme.palette.background.default }}>
                
                <Sidebar />

                <ErrorBoundary fallback={<ErrorPage />}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                    >
                        <MDBox sx={mainBoxStyle()} width={"100%"}>
                            <Grid container flexDirection={"column"} height={"100%"} width={"100%"} gap={1}
                                flexWrap={"nowrap"} minHeight={"100vh"} pt={"16px"} pr={"24px"}>
                                <Grid item flex={1} width={"100%"} sx={{ overflowX: "auto" }}>
                                    <Routes>
                                        {getRoutes(routes)}
                                        <Route path="/shopify/callback" element={<ShopifyCallback />} />
                                        <Route path="/subscriptions/callback" element={<SubscriptionCallback />} />
                                        <Route path="/subscriptions/:subscriptionName" element={<SubscriptionRedirect />} />
                                        <Route path="*" element={<Navigate to="/home" />} />
                                    </Routes>
                                </Grid>

                                <Grid item>
                                    <Footer />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MsalAuthenticationTemplate>
                </ErrorBoundary>
            </MDBox>

            <LoadingModal />
            <SelectTemplateModal />
            <FailedPaymentModal />
        </>
    )
}