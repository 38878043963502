import { Box, Typography } from '@mui/material';
import MDCard from 'material-ui/components/MDCard';
import CheckedImage from '../../../assets/images/checked.png';
import ConfettisImage from '../../../assets/images/confettis.png';

const SubscriptionCallback = () => {
  return (
    <>
      <MDCard border={false} boxShadow={false} sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={ConfettisImage} alt='Conffetis' width={'450px'} />
          <img src={CheckedImage} alt='Checked' width={'45px'} style={{ position: 'absolute', top: '30vh' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 4, justifyContent: 'center' }}>
          <Typography variant='body2' fontSize={'22px'} fontWeight={'bold'}>
            Payment Successful! 🎉
          </Typography>
          <Typography variant='body2'>
            Thank you for your payment! Your transaction has been completed securely through Stripe.
          </Typography>
          <Typography variant='body2'>
            You can now proceed to enjoy our services. If you have any questions or need assistance, feel free to reach out to our support team.
          </Typography>
          <Typography variant='body2' fontSize={'38px'} fontWeight={'bolder'}>
            Welcome to LettrLabs!
          </Typography>
        </Box>
      </MDCard>
    </>
  );
};

export default SubscriptionCallback;